/*
 * @Descripttion: 
 * @version: 
 * @Author: 王文卓
 * @Date: 2024-07-30 17:15:55
 * @LastEditors: 王文卓
 * @LastEditTime: 2024-07-31 11:28:53
 */
import http from '../utils/request.js'

export const downFileApi = (params) => http.get('/filetransfer/downloadfile2', params)