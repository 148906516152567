/*
 * @Descripttion: 
 * @version: 
 * @Author: 王文卓
 * @Date: 2024-07-30 17:15:55
 * @LastEditors: 王文卓
 * @LastEditTime: 2024-07-31 14:51:23
 */

import Vue from 'vue'
import HomeView from '../views/HomeView.vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
