/*
 * @Descripttion: 
 * @version: 
 * @Author: 王文卓
 * @Date: 2024-07-30 17:15:55
 * @LastEditors: 王文卓
 * @LastEditTime: 2024-07-31 11:26:41
 */

import axios from 'axios'


const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_AP,
    timeout: 1000 * 100,
    withCredentials: true,
    responseType: "blob",

})

/**
 * 请求拦截
 */
instance.interceptors.request.use(config => {
    //使用cookie 效验token
    let token = localStorage.getItem('token')
    config.headers['Authorization'] = token // 请求头带上token

    return config
}, error => {
    console.log('网络请求超时，请稍后重试...')
    return Promise.reject(error)
})

/**
 * 响应拦截
 */
instance.interceptors.response.use(response => {
    if (response.data && response.status === 200) {
        return response
    } else {
        return response
    }
}, err => {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                console.log('请求错误')
                break
            case 401:
                console.log('授权过期，请重新登录')
                break
            case 403:
                console.log('拒绝访问')
                break
            case 404:
                console.log(`请求地址出错: ${err.response.config.url}`)
                break
            case 408:
                console.log('请求超时')
                break
            case 500:
                console.log('服务器内部错误')
                break
            case 501:
                console.log('服务未实现')
                break
            case 502:
                console.log('网关错误')
                break
            case 503:
                console.log('服务不可用')
                break
            case 504:
                console.log('网关超时')
                break
            case 505:
                console.log('HTTP版本不受支持')
                break
            default:
                console.log('未知错误，请联系管理员')
        }
    }
    return Promise.reject(err)
})

class http {
    static async get(url, params) {
        let res = await instance.get(url, { params })
        return res
    }
    static async post(url, params) {
        let res = await instance.post(url, params)
        return res
    }
}

export default http

