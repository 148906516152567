<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王文卓
 * @Date: 2024-07-30 17:15:55
 * @LastEditors: 王文卓
 * @LastEditTime: 2024-07-31 15:06:12
-->
<template>
    <div id="app">
        <div class="abody">
            <van-loading v-if="loading" size="60px">下载中...</van-loading>
            <van-button v-else type="primary" @click="downFileBtn">点击下载</van-button>

        </div>
    </div>
</template>

<script>
import 'vant/lib/button/style';
import 'vant/lib/loading/style';
import { downFileApi } from "../api/home.js";
export default {
    name: 'App',
    data() {
        return {
            loading: false,
            data: {},
            fileList: [
                { label: "doc", value: "application/msword" },
                { label: "docx", value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", },
                { label: "gif", value: "image/gif" },
                { label: "jar", value: "application/java-archive" },
                { label: "jpe", value: "image/jpeg" },
                { label: "jpeg", value: "image/jpeg" },
                { label: "jpg", value: "image/jpeg" },
                { label: "mp3", value: "audio/mpeg" },
                { label: "mp4", value: "video/mp4" },
                { label: "ogg", value: "audio/ogg" },
                { label: "pdf", value: "application/pdf", },
                { label: "ppt", value: "application/vnd.ms-powerpoint" },
                { label: "pptx", value: "application/vnd.openxmlformats-officedocument.presentationml.presentation", },
                { label: "rar", value: "application/octet-stream" },
                { label: "tar", value: "application/x-tar" },
                { label: "taz", value: "application/x-tar" },
                { label: "tgz", value: "application/x-compressed" },
                { label: "ttf", value: "application/octet-stream" },
                { label: "web", value: "application/vnd.xara" },
                { label: "wps", value: "application/vnd.ms-works" },
                { label: "xls", value: "application/vnd.ms-excel" },
                { label: "xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", },
                { label: "zip", value: "application/zip" },
            ]
        };
    },
    components: {},
    computed: {
        fileSit() {
            return (item) => {
                let strDate = ''
                const date = new Date()
                const Y = date.getFullYear() + '-'
                const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
                const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
                const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
                const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
                const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
                strDate = Y + M + D + h + m + s

                this.fileList.forEach(e => {
                    if (e.value === item) {
                        return strDate + '.' + e.label
                    }
                })

            }
        },
    },
    watch: {},
    created() {
        this.data = this.$route.query
        localStorage.setItem('token', this.data.token)
        setTimeout(() => {
            this.downFileBtn()
        }, 800);

    },
    activated() { },
    methods: {
        downFileBtn() {
            this.loading = true
            let params = this.data
            delete params.token
            downFileApi(params).then(res => {

                const blob = new Blob([res.data], { type: res.data.type });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                let fileType = res.headers['content-type']
                let fileName = res.headers['content-disposition']
                if (fileName.split('=').length > 0) {
                    fileName = fileName.split('=')[1]
                } else {
                    fileName = this.fileSit(fileType)
                }

                link.setAttribute('download', fileName); // 设置文件名和扩展名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                this.loading = false
            })
        },
    },
}
</script>

<style scoped >
#app {
    height: 100vh;
    width: 100vw;
}

.abody {
    height: 60vh;
    width: 100vw;
    padding: 20vh 0;
}
</style>